import React from 'react';
import * as styles from './FactTitleBox.module.css';
import clsx from 'clsx';

export default function FactTitleBox({ className, titleClassName, title=null, content}) {
  return (
    <section className={ clsx(styles.factTitleBox, className) }>
      <div className={ clsx(styles.title, titleClassName, !title && styles.noTitle ) }><span className='align-sub leading-none'>{ title }</span></div>
      <div className={ styles.content }>{ content }</div>
    </section>
  );
}