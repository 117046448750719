import React from 'react';

import Layout from '../components/Layout';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Hero from '../components/Hero/Hero';
import Title from '../components/Title/Title';
import Tabs from '../components/Tabs/Tabs';
import InnerShadowBox from '../components/InnerShadowBox/InnerShadowBox';
import Infobox from '../components/Infobox/Infobox';
import Columns from '../components/Columns/Columns';
import FactTitleBox from '../components/FactTitleBox/FactTitleBox';
import Footnotes from '../components/Footnotes/Footnotes';
import FactTwoCols from '../components/FactTwoCols/FactTwoCols';
import Button from '../components/Button/Button';
import ColumnsConnections from '../components/ColumnsConnections/ColumnsConnections';
import Months from '../components/Months/Months';
import ImageAndPercentage from '../components/ImageAndPercentage/ImageAndPercentage';
import NumberAndText from '../components/NumberAndText/NumberAndText';


import heroImg from '../images/results/hero.png';
import personYellowImg from '../images/results/person-yellow.png';
import personGrayImg from '../images/results/person-gray.png';
import couple1Img from '../images/results/couple-1.png';
import couple2Img from '../images/results/couple-2.png';
import oneFromFive from '../images/results/one-from-five.png';
import TumorsMayShrinkMobile from '../images/results/tumors-may-shrink-m.inline.svg';
import PeopleWithLoqtorziAndChemo from '../images/results/people-with-loqtorzi-and-chemo.inline.svg';
import PeopleWithChemoAlone from '../images/results/people-with-chemo-alone.inline.svg';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>What were the results from the study | Loqtorzi™ Patient website </title>
      <meta name='description'content='Understand Loqtorzi™ clincal trial results'/>
    </>
  )
}

export default function Results() {
  const tabContent = [
    {
      title: <span className='text-center'>LOQTORZI™ + <br/>chemotherapy*</span>,
      text: <section className='box-shadow-tablet desktop:!pt-[4.2rem] desktop:!pb-[4.8rem] desktop:!pl-[5.2rem] large:!pr-[10rem]'>
        <Title>
          <h3 className='desktop:!text-[1.8rem]'>Study design</h3>
        </Title>
        <div className='mt-[1.7rem]'>The efficacy and safety of treatment with LOQTORZI™ + chemotherapy* was studied in people whose NPC had spread to other parts of the body (metastatic) or had returned (recurrent) in nearby tissues (locally advanced) and who had not received chemotherapy or radiotherapy in the previous 6 months.</div>

        <div className='mt-[3.5rem] desktop:mt-[4rem]'><strong>In this study of 289 people:</strong></div>

        <div className='mt-[2.2rem] text-center tablet:text-left'>
          <img className='mx-auto tablet:inline-block' src={ personYellowImg } width='130' alt='Person yellow' />
          <div className='mt-[1.6rem] tablet:inline-block tablet:mt-0 tablet:ml-[4.1rem]'>
            A total of 146 people were treated <br/>
            with LOQTORZI™ + chemotherapy<sup>†</sup>
          </div>
        </div>

        <div className='mt-[2.2rem] text-center tablet:mt-[4.5rem] tablet:text-left'>
          <img className='mx-auto tablet:inline-block' src={ personGrayImg } width='130' alt='Person gray' />
          <div className='mt-[1.6rem] tablet:inline-block tablet:mt-0 tablet:ml-[4.1rem]'>
            A total of 143 people received <br/>
            chemotherapy<sup>‡</sup> alone
          </div>
        </div>

        <Infobox className={'mt-[3.4rem] pb-[.8rem] tablet:mt-[7rem]'}>
          After the first 6 cycles, people who received LOQTORZI™ + chemotherapy<sup>†</sup> continued with LOQTORZI™ <span className='text-gold-600'>chemo free</span> for up to 24 months
        </Infobox>


        <Footnotes className={'mt-[3rem]'} items={[
          {
            bullet: '*',
            text: <span>Chemotherapy is cisplatin and gemcitabine.</span>
          },
          {
            bullet: <sup>†</sup>,
            text: <span>In the clinical study, cisplatin and gemcitabine were given every 3 weeks for the first 6 treatment cycles, followed by LOQTORZI™ given alone.</span>
          },
          {
            bullet: <sup>‡</sup>,
            text: <span>In the clinical study, cisplatin and gemcitabine were given with placebo every 3 weeks for the first 6 treatment cycles, followed by placebo given alone.</span>
          }
        ]} />

        <Title className={'mt-[3.5rem] tablet:mt-[7.7rem]'}>
          <h2 className='uppercase'>More time without your disease<br/> <span>getting worse<sup className='font-MontserratMedium'>§</sup></span></h2>
        </Title>

        <div className='mt-[2rem]'>
          <strong>In this study, data were analyzed at different times.</strong>
        </div>

        <div className='mt-[1.7rem] text-[1.3rem] text-blue-600'>
          <strong>Initial study results from May 30, 2020</strong>
        </div>
        <InnerShadowBox className={'mt-[1rem] !pt-0 !pr-0 !pb-[1.4rem] !pl-0 desktop:!block'} onlyMobile={true}>
          <Columns className={'tablet:gap-[2rem] desktop:gap-[4.3rem] desktop:justify-center'}>
            <InnerShadowBox className={'desktop:max-w-[46.8rem] !pb-[.5rem]'} noMobile={true}>
              <FactTitleBox
                titleClassName='bg-gold-600'
                content={
                  <div className='flex pr-[.5rem] justify-between w-full'>
                    <div className='uppercase desktop:flex desktop:items-center'>
                      <div>
                        <div className='font-MontserratBold text-[1.5rem] desktop:text-[1.8rem]'>LOQTORZI™ + chemotherapy<sup>†</sup></div>
                        <div className='text-[1rem] uppercase desktop:text-[1.3rem]'>delayed cancer <br className='tablet:hidden'/>worsenIng by</div>
                      </div>
                    </div>
                    <Months className={'mt-[-.5rem]'} head="NEARLY" number={12} text={ <span>MONTHS<sup>||</sup></span> }/>
                  </div>
                }/>
            </InnerShadowBox>
            <InnerShadowBox className={'desktop:max-w-[46.8rem] !justify-center desktop:!justify-normal'} noMobile={true}>
              <FactTitleBox
                titleClassName='bg-gray-350'
                content={
                  <div className='flex pr-[.5rem] justify-between w-full'>
                    <div className='text-gray-350 uppercase desktop:flex desktop:items-center'>
                      <div>
                        <div className='font-MontserratBold text-[1.5rem] desktop:text-[1.8rem]'>chemotherapy<sup>‡</sup> ALONE</div>
                        <div className='text-[1rem] uppercase desktop:text-[1.3rem]'>delayed cancer<br className='tablet:hidden'/> worsenIng by</div>
                      </div>
                    </div>
                    <Months className={'mt-[-1.5rem] text-gray-350'} head="&nbsp;" number={8}/>
                  </div>
                }/>
            </InnerShadowBox>
          </Columns>
        </InnerShadowBox>

        <Footnotes items={[
          {
            bullet: <sup>§</sup>,
            text: <span>Based on median progression-free survival. Median progression-free survival is when 50% of the people experienced disease progression.</span>
          },
          {
            bullet: <sup>||</sup>,
            text: <span>Delayed cancer worsening by 11.7 months.</span>
          }
        ]} />

        <div className='mt-[1.7rem] text-[1.3rem] text-blue-600'>
          <strong>Final study results from June 8, 2021</strong>
        </div>
        <InnerShadowBox className={'mt-[1rem] !pt-0 !pr-0 !pb-[1.4rem] !pl-0 desktop:!block'} onlyMobile={true}>
          <Columns className={'tablet:gap-[2rem] desktop:gap-[4.3rem] desktop:justify-center'}>
            <InnerShadowBox className={'desktop:max-w-[46.8rem]'} noMobile={true}>
              <FactTitleBox
                titleClassName='bg-gold-600'
                content={
                  <div className='flex pr-[.5rem] justify-between w-full'>
                    <div className='uppercase desktop:flex desktop:items-center'>
                      <div>
                        <div className='font-MontserratBold text-[1.5rem] desktop:text-[1.8rem]'>LOQTORZI™ + chemotherapy<sup>†</sup></div>
                        <div className='text-[1rem] uppercase desktop:text-[1.3rem]'>
                          DELAYED CANCER <br className='tablet:hidden'/>WORSENING BY
                        </div>
                      </div>
                    </div>
                    <Months className={'mt-[-.5rem]'} head={<span>more&nbsp;than</span>} number={21}/>
                  </div>
                }/>
            </InnerShadowBox>
            <InnerShadowBox className={'desktop:max-w-[46.8rem]'} noMobile={true}>
              <FactTitleBox
                titleClassName='bg-gray-350'
                content={
                  <div className='flex pr-[.5rem] justify-between w-full'>
                    <div className='text-gray-350 uppercase desktop:flex desktop:items-center'>
                      <div>
                        <div className='font-MontserratBold text-[1.5rem] desktop:text-[1.8rem]'>chemotherapy<sup>‡</sup> ALONE</div>
                        <div className='text-[1rem] uppercase desktop:text-[1.3rem]'>
                          delayed cancer <br className='tablet:hidden'/>worsenIng by <br className='hidden large:block'/>
                          </div>
                      </div>
                    </div>
                    <Months className={'mt-[-.5rem] text-gray-350'} head={<span>more&nbsp;than</span>} number={8}/>
                  </div>
                }/>
            </InnerShadowBox>
          </Columns>
        </InnerShadowBox>

        <div className='mt-[1.4rem]'>People who took LOQTORZI™ + chemotherapy<sup>†</sup> lived an additional 1+ year without their <br className='hidden large:block'/> cancer getting worse.<sup>§</sup></div>

        <ImageAndPercentage className={'mt-[2.7rem] tablet:mt-[1.7rem]'}
          text={<span>LOQTORZI™ + chemotherapy<sup>†</sup> reduced <br className='hidden desktop:block'/>the risk of disease worsening by </span>}
          subtext={<>compared with chemotherapy<sup>‡</sup> alone</>}
          number={ '48%' }
          image= { {
            src: couple1Img,
            alt: 'couple hug'
          } }
        />

        <Footnotes className={'mt-[2.9rem] desktop:mt-[5rem]'} items={[
          {
            bullet: '*',
            text:<span>Chemotherapy is cisplatin and gemcitabine.</span>
          },
          {
            bullet: <sup>†</sup>,
            text:<span>In the clinical study, cisplatin and gemcitabine were given every 3 weeks for the first 6 treatment cycles, followed by LOQTORZI™ given alone.</span>
          },
          {
            bullet: <sup>‡</sup>,
            text:<span>In the clinical study, cisplatin and gemcitabine were given with placebo every 3 weeks for the first 6 treatment cycles, followed by placebo given alone.</span>
          },
          {
            bullet: <sup>§</sup>,
            text:<span>Based on median progression-free survival. Median progression-free survival is when 50% of the people experienced disease progression.</span>
          }
        ]}/>

        <Title className={'mt-[7.4rem] desktop:mt-[6.6rem]'}>
          <h2 className='uppercase'>More likely to live longer than with chemo alone</h2>
        </Title>

        <div className='mt-[1.3rem] tablet:mt-[3rem]'>
          In the final analysis (median follow-up of 36 months), 61% (89 of 146) of people treated with LOQTORZI™ + chemotherapy<sup>†</sup> were alive, compared with 47% (67 of 143) of people treated with chemotherapy<sup>‡</sup> alone.
        </div>

        <ImageAndPercentage
          className={'mt-[2.7rem] tablet:mt-[1.7rem]'}
          numberClassName={'!bottom-[2.3rem]'}
          text={<span className='inline-block pt-[1rem] desktop:pt-0'>LOQTORZI™ + chemotherapy<sup>†</sup> <br/>reduced the risk of death by</span>}
          subtext={<span>compared with chemotherapy<sup>‡</sup> alone</span>}
          number={ '37%' }
          image= { {
            src: couple2Img,
            alt: 'couple hug'
          } }
        />

        <Infobox className={'mt-[4rem] pb-[.8rem] desktop:mt-[4.7rem]'}>
          Reduced risk of death means more time together
        </Infobox>

        <Footnotes className={'mt-[2.9rem]'} items={[
          {
            bullet: '*',
            text:<span>Chemotherapy is cisplatin and gemcitabine.</span>
          },
          {
            bullet: <sup>†</sup>,
            text:<span>In the clinical study, cisplatin and gemcitabine were given every 3 weeks for the first 6 treatment cycles, followed by LOQTORZI™ given alone.</span>
          },
          {
            bullet: <sup>‡</sup>,
            text:<span>In the clinical study, cisplatin and gemcitabine were given with placebo every 3 weeks for the first 6 treatment cycles, followed by placebo given alone.</span>
          }
        ]}/>

        <Title className={'mt-[4.8rem] desktop:mt-[6.6rem]'}>
          <h2>YOUR TUMORS MAY SHRINK OR DISAPPEAR</h2>
        </Title>
        <p className='mt-[1.3rem] font-MontserratSemiBold text-[1.4rem] text-blue-600 tablet:mt-[3rem]'>
          Initial study results from May 30, 2020
        </p>

        <TumorsMayShrinkMobile className='mx-auto tablet:hidden' />

        <div className='hidden tablet:block'>
          <NumberAndText className={'mt-[3rem] text-blue-600 tablet:mt-[4rem]'}
            number={'77%'}
            text={<span>of people responded to treatment with LOQTORZI™ + chemotherapy<sup>†</sup></span>} />

            <InnerShadowBox className={'mt-[4rem] !pt-[2.2rem] !pb-[1.3rem] !block desktop:mt-[1.4rem]'}>
              <PeopleWithLoqtorziAndChemo className={ 'w-full h-auto' }/>
            </InnerShadowBox>

          <NumberAndText className={'mt-[2.8rem] text-gray-600 tablet:mt-[3.6rem]'}
          number={'66%'}
          text={<span>of people responded to treatment with chemotherapy<sup>‡</sup> alone</span>} />

          <InnerShadowBox className={'mt-[4rem] !pt-[2.2rem] !pb-[1.3rem] !block desktop:mt-[1.4rem]'}>
            <PeopleWithChemoAlone className={ 'w-full h-auto' }/>
          </InnerShadowBox>
        </div>

        <Footnotes className={'mt-[4.4rem] desktop:mt-[4rem]'} items={[
          {
            bullet: '*',
            text: <span>Chemotherapy is cisplatin and gemcitabine.</span>
          },
          {
            bullet: <sup>†</sup>,
            text:<span>In the clinical study, cisplatin and gemcitabine were given every 3 weeks for the first 6 treatment cycles, followed by LOQTORZI™ given alone.</span>
          },
          {
            bullet: <sup>‡</sup>,
            text:<span>In the clinical study, cisplatin and gemcitabine were given with placebo every 3 weeks for the first 6 treatment cycles, followed by placebo given alone.</span>
          }
        ]}/>

        <div className='mt-[4.4rem] font-MontserratSemiBold text-[1.7rem] leading-[1.2] text-center text-blue-600 uppercase tablet:text-[2rem] tablet:mt-[5rem]'>
          Discover how <br className='desktop:hidden'/>LOQTORZI™ may help you
        </div>

        <div className='mt-[2.7rem] text-center desktop:mt-[4rem]'>
          <Button type='link' url='/stay-in-touch/'>
            Stay in the know
          </Button>
        </div>
      </section>
    },
    {
      title: <span className='text-center'>LOQTORZI™<br />alone</span>,
      text: <section className='box-shadow-tablet desktop:!pt-[4.2rem] desktop:!pr-[10rem] desktop:pb-[6.2rem] desktop:!pl-[5.2rem]'>
        <div>
          <Title>
            <h3 className='desktop:!text-[1.8rem]'>Study design</h3>
          </Title>
          
          <div className='mt-[1.7rem]'>The efficacy and safety of treatment with LOQTORZI™ alone was studied in a clinical trial of 172 people whose NPC had returned (recurrent) and could not be removed with surgery or had spread (metastatic), and who had received chemotherapy that contained platinum, and it did not work or was no longer working.</div>

          <Title className={'mt-[5rem]'}>
            <h2 className='uppercase'>Your tumors may shrink or disappear</h2>
          </Title>

          <div className='w-[30.4rem] tablet:w-[55.2rem] mx-auto'>
            <FactTwoCols className={'justify-center max-w-[59.7rem] mt-[2rem] pl-[1rem] text-blue-600 desktop:mt-[3rem] desktop:!gap-[2rem]'}
              amount={ <div className='inline-block mt-[1.25rem] align-bottom text-[2rem] tablet:mt-[1.25rem] tablet:text-[2.8rem]'>
                <div className='flex justify-between items-center mb-[.6rem] px-[.3rem] whitespace-nowrap leading-none'>
                  <span className='font-MontserratSemiBold text-[4rem] leading-[0] tablet:text-[5rem]'>1</span>
                  <span className='align-super'>IN</span>
                  <span className='font-MontserratSemiBold text-[4rem] leading-[0] tablet:text-[5rem]'>5</span>
                </div>
                PEOPLE</div> }
              content={<div className='text-[1.6rem] leading-[1.25] tablet:text-[2.3rem] tablet:leading-[1.1]'>saw their tumors shrink <br/>or completely disappear <br/>with LOQTORZI™</div>}
            />
            <div className='mt-[1.5rem] pt-[1.5rem] border-t-2 border-gold-600'>
              <img src={ oneFromFive } alt='One person from five' />
            </div>
          </div>

            <InnerShadowBox className={'mt-[3.3rem] mx-auto desktop:!block desktop:max-w-[95.9rem] desktop:mt-[8rem]'}>
              <Columns className={'tablet:gap-[6rem]'} yellowPipe={ false }>
                <FactTitleBox
                  className={'mt-[2.8rem] tablet:mt-0'}
                  titleClassName='bg-gold-op75 text-white'
                  title={'19%'}
                  content={ <span className='inline-block'>of people had their tumors&nbsp;shrink</span> }/>

                <FactTitleBox
                  className={'mt-[2.8rem] tablet:mt-0'}
                  titleClassName='bg-gold-600 text-white'
                  title={'~2%'}
                  content={ <span>of people had their tumors&nbsp;disappear</span> }/>
              </Columns>
            </InnerShadowBox>
          </div>
          <div className='mt-[4.4rem] font-MontserratSemiBold text-[1.7rem] leading-[1.2] text-center text-blue-600 uppercase tablet:text-[2rem] tablet:mt-[3.8rem]'>
            Discover how <br className='desktop:hidden'/>LOQTORZI™ may help you
          </div>

          <div className='mt-[2.7rem] text-center desktop:mt-[4rem]'>
            <Button type='link' url='/stay-in-touch/'>
              Stay in the know
            </Button>
          </div>
      </section>
    }
  ];

  return (
    <Layout>
      <Hero image={ { src: heroImg, alt:'Hero about NPC' } }>
        <Title>
          <h1>What were the results from the study?</h1>
        </Title>
      </Hero>
      <GlobalWrapper className={'mobileFull'}>
        <Tabs className={'box-shadow tablet:shadow-none mt-[1.5rem]'} content={ tabContent } />
      </GlobalWrapper>
      <GlobalWrapper className={'desktop:pb-[8rem]'}>
        <ColumnsConnections className={'mt-[2rem] tablet:mt-[5.6rem] desktop:mt-[7rem]'}/>
      </GlobalWrapper>
    </Layout>
  );
}
