import React from 'react';
import * as styles from './ImageAndPercentage.module.css';
import clsx from 'clsx';

export default function ImageAndPercentage({ className, numberClassName, text, subtext, number, image }) {
  return (
    <div className={ clsx(styles.imageAndPercentage, className) }>
      <div className={ styles.image }>
        <img src={ image.src } alt={ image.alt } /> 
      </div>
      <div className={ styles.content }>
        <div className={ styles.textNum }>
          <div className={ styles.text }>
            { text }
          </div>
          <div  className={ clsx(styles.number, numberClassName) }>
            { number }
          </div>
        </div>
        <div className={ styles.line }></div>
        {
          subtext && <div className={ styles.subtext }>{ subtext }</div>
        }
      </div>
    </div>
  );
}