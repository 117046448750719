
import React from 'react';
import clsx from 'clsx';

export default function Months({ className, head, number, text}) {
  return (
    <div className={ clsx('font-MontserratBold text-center', className) }>
      {
        head &&
        <div className=' text-[1.3rem] uppercase'>{ head }</div>
      }
      <div className='mt-[-.4rem] text-[5.5rem] leading-none'>{ number }</div>
      <div className='text-[1.6rem] uppercase leading-[.6]'>{ text || 'months' }</div>
    </div>
  );
}
