import React from 'react';
import clsx from 'clsx';

export default function NumberAndText({ className, number, text }) {
  return (
    <div className={ clsx( 'flex flex-col items-center gap-[.5rem] tablet:flex-row', className) }>
      <div className='font-MontserratBold text-[4.5rem] leading-[1.22] tablet:text-[5rem]'>
        { number }
      </div>
      <div className='font-MontserratMedium text-[1.3rem] leading-none uppercase tablet:text-[2rem]'>
        { text }
      </div>
    </div>
  );
}