import React from 'react';
import * as styles from './InnerShadowBox.module.css';
import clsx from 'clsx';

export default function InnerShadowBox({ className, children, onlyMobile=false, noMobile=false }) {
  return (
    <div className={ clsx(styles.innerShadowBox, className, onlyMobile && styles.mobile, noMobile && styles.mobileHidden) }>
      { children }
    </div>
  );
}