import React from 'react';
import * as styles from './Infobox.module.css';
import clsx from 'clsx';

export default function Infobox({ className, children }) {
  return (
    <section className={ clsx(styles.infobox, className) }>
      { children }
    </section>
  );
}