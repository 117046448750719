import React from 'react';
import * as styles from './FactTwoCols.module.css';
import clsx from 'clsx';

export default function FactTwoCols({ className, amount, content }) {
  return (
    <div className={ clsx(styles.factTwoCols, className) }>
      <span className={ styles.amount }>{ amount }</span>
      <div className={ styles.content }>{ content }</div>
    </div>
  );
}